import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsPipe, string_to_slug } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, ClientEnv, Ic2ToastrService, JoinResultDTO, JoinViaCodeService } from 'common';

@Component({
  selector: 'hiji-join-session',
  templateUrl: './join-session.component.html',
  styleUrls: ['./join-session.component.scss'],
})
export class JoinSessionComponent {
  code: string = null;
  loading: boolean = false;

  constructor(
    private router: Router,
    private joinViaCodeService: JoinViaCodeService,
    private ic2ToastrService: Ic2ToastrService,
    private translate: TranslateService,
    private msPipe: MsPipe,
    private authService: AuthService,
    private clientEnv: ClientEnv,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      this.code = params['joinCode'];
      if (this.code) this.join();
    });
  }

  join() {
    if (!this.code) return;
    this.loading = true;
    this.joinViaCodeService
      .join(this.code)
      .onBusinessErrorCode('invalid_code', () => {
        this.ic2ToastrService.showDanger(this.translate.instant('certification.join-session.Code invalide'));
        this.loading = false;
      })
      .onBusinessErrorCode('already_in', () => {
        this.ic2ToastrService.showSuccess(this.translate.instant('certification.join-session.Vous êtes déjà dans cette session'));
        this.loading = false;
      })
      .useDefaultOnErrorOnOtherErrorTypes()
      .execute((data: JoinResultDTO) => {
        if (data.campaignName !== null) {
          this.ic2ToastrService.showSuccess(
            this.translate.instant('certification.join-session.Vous avez rejoint la session de coaching {{sessionName}}', {
              sessionName: this.msPipe.transform(data.campaignName),
            })
          );
          if (this.clientEnv.is(this.clientEnv.SKILLSUP))
            this.router.navigate([
              '/skillsup/coaching',
              this.authService.userBundle.user.idUser,
              string_to_slug(this.authService.userBundle.user.firstName + ' ' + this.authService.userBundle.user.lastName),
            ]);
          else
            this.router.navigate([
              '/user',
              this.authService.userBundle.user.idUser,
              string_to_slug(this.authService.userBundle.user.firstName + ' ' + this.authService.userBundle.user.lastName),
            ]);
        } else if (data.idEMATemplate !== 0) {
          this.ic2ToastrService.showSuccess(
            this.translate.instant('certification.join-session.Vous avez rejoint la session de coaching {{sessionName}}', {
              sessionName: '',
            })
          );
          if (this.clientEnv.is(this.clientEnv.SKILLSUP))
            this.router.navigate([
              '/skillsup/coaching',
              this.authService.userBundle.user.idUser,
              string_to_slug(this.authService.userBundle.user.firstName + ' ' + this.authService.userBundle.user.lastName),
            ]);
          else
            this.router.navigate([
              '/user',
              this.authService.userBundle.user.idUser,
              string_to_slug(this.authService.userBundle.user.firstName + ' ' + this.authService.userBundle.user.lastName),
            ]);
        } else {
          this.ic2ToastrService.showSuccess(
            this.translate.instant('certification.join-session.Vous avez rejoint la session de certification {{sessionName}}', {
              sessionName: this.msPipe.transform(data.certifName) + ' ' + data.sessionName,
            })
          );

          this.router.navigate([
            '/certification',
            data.idCertification,
            string_to_slug(this.msPipe.transform(data.certifName)),
            'session',
            data.idCertificationSession,
            string_to_slug(data.sessionName),
            this.authService.userBundle.user.idUser,
            string_to_slug(this.authService.userBundle.user.firstName + ' ' + this.authService.userBundle.user.lastName),
          ]);
        }

        this.loading = false;
      });
  }
}
